import { Environment } from '@context/shared/types/common';

export const environment: Environment = {
	app: 'web',
	production: false,
	appUrl: 'https://dev.app-context.com',
	apiUrl: 'https://us-central1-dev-papyrus.cloudfunctions.net/api',
	supportEmail: 'support@app-context.freshdesk.com',
	algolia: {
		apiKey: 'ad536c3d78abfd2f4a72240a9ab90d11',
		applicationId: 'O1FSIQX1HZ',
	},
	firebase: {
		apiKey: 'AIzaSyCVjinrxOw32GUmxOysT5PlPH3BWWABp3U',
		authDomain: 'dev-papyrus.firebaseapp.com',
		projectId: 'dev-papyrus',
		storageBucket: 'dev-papyrus.appspot.com',
		messagingSenderId: '621818219700',
		appId: '1:621818219700:web:1f37767078aa2145791be1',
		measurementId: 'G-RP6BP6WFZN',
	},
};
